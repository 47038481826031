import React, { Component } from "react";
import PageHelmet from "../components/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactForm from "../components/contact/ContactForm";

import Layout from "../components/layout/Layout"

import BGImage from "gatsby-background-image"
import RoninworksIcon from "../components/RoninworksIcon"

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 39.116966,
            lng: -77.198972            
        },
        zoom: 10
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />
                <Layout>

                    {/* Start Breadcrump Area */}
                    {/* <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--31"  data-black-overlay="6"> */}
                    <BGImage
                        fluid={this.props.data.headerBackgroundImage.childImageSharp.fluid}>
                        <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">GET IN TOUCH</h2>
                                            <p>Feel free to use the form below or email us directly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BGImage>
                    {/* End Breadcrump Area */}


                    {/* Start Contact Top Area  */}
                    <div className="rn-contact-top-area ptb--120 bg_color--5">
                        <div className="container">
                        
                            <div className="row">
                                {/* Start Single Address  */}
                                {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiHeadphones />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Contact With Phone Number</h4>
                                            <p><a href="tel:+057 254 365 456">+057 254 365 456</a></p>
                                            <p><a href="tel:+856 325 652 984">+856 325 652 984</a></p>
                                        </div>
                                    </div>
                                </div> */}
                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiMail />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Email</h4>
                                            <p><a href="mailto:info@roninworks.io">info@roninworks.io</a></p>
                                            <p><a href="mailto:sales@roninworks.io">sales@roninworks.io</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                                {/* Start Single Address  */}
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                    <div className="rn-address">
                                        <div className="icon">
                                            <FiMapPin />
                                        </div>
                                        <div className="inner">
                                            <h4 className="title">Address</h4>
                                            <p>Roninworks <span style={{fontSize: '0.5em'}}>(Please include our name if sending mail)</span></p>
                                            <p>9841 Washington Blvd, Suite 200</p>
                                            <p>Gaithersburg, MD 20878</p>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Address  */}

                            </div>
                        </div>
                    </div>
                    {/* End Contact Top Area  */}

                    {/* Start Contact Page Area  */}
                    <div className="rn-contact-page ptb--120 bg_color--1">
                        <ContactForm />
                    </div>
                    {/* End Contact Page Area  */}

                    {/* Start Contact Map  */}
                    <div className="rn-contact-map-area position-relative">
                        <div style={{ height: '650px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyCV0H71WYXbwiRxNPGUu04YS02KKDEMSdY' }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                            >
                                <div 
                                    style={{width: 50}}
                                    lat={39.116966}
                                    lng={-77.198972}
                                    text="9841 Washington Blvd"
                                >
                                    <RoninworksIcon />
                                </div>
                            </GoogleMapReact>
                        </div>
                    </div>
                    
                    <br />
                    
                    {/* End Contact Map  */}
                
                </Layout>
            </React.Fragment>
        )
    }
}
export default Contact

export const query = graphql`
    query {
        headerBackgroundImage: file(relativePath: { eq: "contact-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`